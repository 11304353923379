@font-face {
  font-family: "Circular-Book";
  src: url("../fonts/lineto-circular-black.eot");
  src: url("../fonts/lineto-circular-black.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-black.woff") format("woff");
  font-weight: 900;
  font-style: normal
}

@font-face {
  font-family: "Circular-Book";
  src: url("../fonts/lineto-circular-bold.eot");
  src: url("../fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Circular-Book";
  src: url("../fonts/lineto-circular-book.eot");
  src: url("../fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-circular-book.woff") format("woff");
  font-weight: normal;
  font-style: normal
}