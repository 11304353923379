@import '../../scss/variables';

body.menu-open {
  #mainMap.mapboxgl-map {
    @include breakpoint(md) {
      max-width: calc(100vw - #{$menu-max-width});
    }
  }
}

.chakra-ui-dark {
  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: var(--chakra-colors-chakra-body-bg);
  }
  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: var(--chakra-colors-chakra-body-bg);
  }
}

#mainMap {
  height: calc(var(--vh, 1vh) * 100) !important;
  width: 100vw;
  max-width: 100vw;
  margin-left: auto;
  // transition: max-width 0.2s ease;

  font-family: inherit;

  &.map-cursor-crosshair {
    .mapboxgl-canvas-container.mapboxgl-interactive {
      cursor: crosshair;
    }
  }

  &.mapboxgl-map {
    font-size: 17px;
  }

  .mapboxgl-ctrl-top-right {
    margin-top: 5rem;
  }

  .mapboxgl-ctrl-logo {
    display: none;
  }

  .mapbox-control-ruler > button {
    svg {
      fill: #000;
    }
  }

  .mapbox-control-ruler > button.-active {
    border-color: $color-blue;
    background-color: $color-blue;
    svg {
      fill: #fff;
    }
  }

  .mapboxgl-ctrl-group {
    box-shadow: none;
    background: none;
    margin: 0 1rem 1rem 0;

    &:empty {
      display: none;
    }

    & > button,
    & > .button {
      display: block;
      background: #fff;
      margin-bottom: 0.5rem;
      height: 3rem;
      width: 3rem;
      border-radius: 3px;
      box-shadow: $box-shadow-on-map;
      padding: 0;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      .mapboxgl-ctrl-icon {
        background-color: #fff;
        background-size: 80%;
      }
    }
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/zoom-in.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/zoom-out.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url('/components/Icon/svgs/control.svg');
    height: 32px;
    width: 32px;
  }

  .mapboxgl-ctrl-scale {
    background-color: transparent;
    margin: 1rem 0 0 1rem;
    color: #5a5a5a;
    border-color: #5a5a5a;
  }

  .property-map-marker {
    cursor: pointer;
    color: $color-primary;
    transition: transform 0.4s ease;

    &:hover {
      transform: scale(1.5);
      color: #333;
    }

    &--selected {
      transform: scale(3);
      &:hover {
        color: $color-primary;
        transform: scale(3);
      }
    }

    &--notselected {
      transform: scale(1);
      opacity: 0.8;
      color: #333;
    }

    path {
      stroke: #f9f9f9;
      stroke-width: 0.2px;
    }
  }

  .tooltip {
    position: absolute;
    margin: 0.5rem;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 18rem;
    font-size: 0.8rem;
    z-index: 9;
    pointer-events: none;

    &--fixed {
      position: fixed;
    }

    &__feature {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin: 0;
      }

      &__name {
        font-weight: bold;
      }
    }
  }

  .mapboxgl-popup-content {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }

  .mapboxgl-popup {
    width: auto;
    margin-top: 1rem;
  }

  .schools-list {
    padding-top: 1rem;
    > div {
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0;
      margin-bottom: 0.6rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .mapboxgl-popup-close-button {
    $positionTop: -1rem;
    $positionRight: -1rem;
    font-size: 2rem;
    text-indent: -99999px;
    border-radius: 100%;
    background-color: #424242;
    height: 2rem;
    width: 2rem;
    top: $positionTop;
    right: $positionRight;
    padding: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #5a5a5a;
    }

    &::after {
      content: '';
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 121.31 122.88'%3E%3Cpath fill-rule='evenodd' d='M90.91 5.3a17.66 17.66 0 0 1 25.16-.07 18.1 18.1 0 0 1 .07 25.4l-30.4 30.82 30.43 30.86a17.94 17.94 0 0 1-.21 25.24 17.65 17.65 0 0 1-25.07-.03L60.67 86.88l-30.28 30.7a17.66 17.66 0 0 1-25.15.07c-6.96-7-7-18.37-.07-25.4l30.4-30.83L5.14 30.57a17.94 17.94 0 0 1 .21-25.25 17.65 17.65 0 0 1 25.07.03L60.64 36 90.91 5.3z' clip-rule='evenodd'/%3E%3C/svg%3E");
      position: absolute;
      filter: invert(1);
      top: $positionTop + 1.566rem;
      right: $positionRight + 1.566rem;
      text-indent: 0;
      height: 0.8rem;
      width: 0.8rem;
    }
  }

  @media screen and (max-width: 768px) {
    .popup {
      min-width: 100% !important;
      width: 100%;
      position: absolute;
      bottom: 0%;
      top: inherit;
      left: 0%;
      transform: none !important;
      z-index: 9 !important;
      .mapboxgl-popup-close-button {
        top: -3rem;
        right: 0rem;
        height: 3rem;
        width: 3rem;
        &::after {
          height: 1.5rem;
          width: 1.5rem;
          top: 0.8rem;
          right: 0.7rem;
        }
      }
      .mapboxgl-popup-close-button :focus {
        outline: none;
      }
      .mapboxgl-popup-tip {
        display: none;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input {
    font-size: 16px;
  }
}
