@import '../../scss/variables';

.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1006;
  cursor: default;

  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 4px solid $color-primary;
      opacity: 1;
      border-radius: 50%;
      animation: loader-animation 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    div:nth-child(2) {
      animation-delay: -0.25s;
    }

    div:nth-child(3) {
      animation-delay: -0.5s;
    }

    div:nth-child(4) {
      animation-delay: -0.75s;
    }
  }
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}